<template>
  <span class="nursery-set-inscription-clocking-modal">
    <b-modal
      size="md"
      :id="modalId"
      cancel-title="Annuler"
      @ok="onConfirm"
      @cancel="onCancel"
      :ok-disabled="okDisabled"
      ok-variant="primary"
      ok-title="Enregistrer"
      @shown="init()"
    >
      <template v-slot:modal-title v-if="inscription">
        <div v-if="isFuture">
          <i class="fa fa-clock-o"></i> Arrivée et départ prévus
        </div>
        <div v-else>
          <i class="fa fa-clock-o"></i> Pointage
        </div>
      </template>
      <div v-if="inscription" class="row-line">
        {{ inscription.date | dateToString }} - {{ inscription.individual.lastAndFirstName() }}
      </div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="inscription && !isLoading(loadingName)">
        <div v-if="hasInvoice" class="warning">
          La journée est facturée. le pointage ne peut pas être modifié.
        </div>
        <div class="nursery-clocking">
          <b-row v-if="!hasArrivalClocking && !splitTracks">
            <b-col cols="3">
              <b-form-group label-for="absence">
                <b-form-checkbox id="absence" v-model="absence" :disabled="cancel">
                  Absence
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-for="paidAbsence">
                <b-form-checkbox id="paidAbsence" v-model="paidAbsence" :disabled="!absence">
                  facturée
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="2"></b-col>
            <b-col>
              <b-form-group label-for="cancel" v-if="allowCancel">
                <b-form-checkbox id="cancel" v-model="cancel" :disabled="absence">
                  Annulation
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="absence">
            <b-col>
              <b-form-group label-for="absenceReason">
                <b-input-group v-if="!showAddReason">
                  <b-form-select v-model="absenceReason" id="absenceReason">
                    <b-form-select-option
                      :value="elt"
                      v-for="elt of absenceReasons"
                      :key="elt.id"
                    >
                      {{ elt.name }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-input-group-append v-if="canAddReason">
                    <b-button
                      variant="secondary"
                      @click.prevent="showAddReason = true"
                      title="Ajouter une raison"
                      v-b-tooltip
                    >
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group v-else>
                  <b-input type="text" v-model="reasonText"></b-input>
                  <b-input-group-append>
                    <b-button
                      variant="secondary"
                      @click.prevent="showAddReason = false"
                      title="Voir la liste des raisons"
                      v-b-tooltip
                    >
                      <i class="fa fa-list"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="showFuture">
            <b-col>
              <b-form-group label-for="arrivalAt" label="Arrivée">
                <b-input
                  type="time"
                  id="arrivalAt"
                  v-model="arrivalAt"
                ></b-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="leavingAt" label="Départ">
                <b-input
                  type="time"
                  id="leavingAt"
                  v-model="leavingAt"
                ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="splitTracks">
            <b-col>
              <b-form-group label-for="arrivalAt2" label="Arrivée2">
                <b-input
                  type="time"
                  id="arrivalAt2"
                  v-model="arrivalAt2"
                ></b-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="leavingAt2" label="Départ2">
                <b-input
                  type="time"
                  id="leavingAt2"
                  v-model="leavingAt2"
                ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!showFuture && showArrival">
            <b-col>
              <b-form-group label-for="arrivedAt" label="Arrivée">
                <b-input
                  type="time"
                  id="arrivedAt"
                  v-model="arrivedAt"
                ></b-input>
                <b-row>
                  <b-col><div class="help-info">{{ arrivedDescription }}</div></b-col>
                  <b-col cols="3" class="set-default-btn">
                    <a href @click.prevent="setDefaultArrivedAt()">
                      {{ displayHourMin(inscription.arrivalAt) }}
                    </a>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="arrivedWith" label="Avec">
                <b-select
                  id="arrivedWith"
                  v-model="arrivedWith"
                  class="small-text"
                >
                  <b-select-option
                    v-for="member of members"
                    :key="member.id"
                    :value="member.individual"
                  >
                    {{ member.individual.firstAndLastName() }}
                    ({{ member.role.name }})
                  </b-select-option>
                </b-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!showFuture && showLeftAt">
            <b-col>
              <b-form-group label-for="leftAt" label="Départ">
                <b-input
                  type="time"
                  id="leftAt"
                  v-model="leftAt"
                ></b-input>
                <b-row>
                  <b-col><div class="help-info">{{ leftDescription }}</div></b-col>
                  <b-col cols="3" class="set-default-btn">
                    <a href @click.prevent="setDefaultLeftAt()">
                      {{ displayHourMin(inscription.leavingAt) }}
                    </a>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="leftWith" label="Avec">
                <b-select
                  id="leftWith"
                  v-model="leftWith"
                  class="small-text"
                >
                  <b-select-option
                    v-for="member of members"
                    :key="member.id"
                    :value="member.individual"
                  >
                    {{ member.individual.firstAndLastName() }}
                    ({{ member.role.name }})
                  </b-select-option>
                </b-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!isAbsence">
            <b-col>
              <b-form-checkbox
                id="lunch"
                v-model="lunch"
              >
                Repas
              </b-form-checkbox>
            </b-col>
            <b-col v-if="!readOnly && canSplit && !isAbsence">
              <a href @click.prevent="onSplitTracks()">
                <span v-if="splitTracks">1 seule plage</span>
                <span v-else>Découper en 2 plages</span>
              </a>
            </b-col>
          </b-row>
        </div>
        <div v-if="instantClocking">
          <a href @click.prevent="instantClocking = false">
            Voir le pointage complet
          </a>
        </div>
        <div v-if="cancel" class="warning-text">
          Attention! Si la séance est annulée, elle ne sera plus visible sur le listing
          et plus prise en compte.
        </div>
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import moment from 'moment'
import { mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'
import { diffDate } from '@/utils/dates'
import { displayHourMin } from '@/utils/time'
import { BackendApi } from '@/utils/http'
import { makeEntity } from '@/types/people'
import { makeAbsenceReason, makeNurseryInscription, NurseryAbsence } from '@/types/nursery'
import { getObjIndex } from '@/utils/arrays'
import { hasLunch } from '@/utils/nursery'
import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'NurserySetInscriptionClockingModal',
  components: {
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    inscription: Object,
    id: {
      type: String,
      default: '',
    },
    tracks: {
      type: Number,
      default: 1,
    },
    allowCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      instantClocking: true,
      arrivalAt: '',
      leavingAt: '',
      arrivalAt2: '',
      leavingAt2: '',
      arrivedAt: '',
      leftAt: '',
      arrivedWith: null,
      leftWith: null,
      absence: false,
      paidAbsence: false,
      members: [],
      loadingName: 'NurserySetInscriptionClockingModal',
      errorText: '',
      absenceReasons: [],
      absenceReason: null,
      showAddReason: false,
      reasonText: '',
      splitTracks: false,
      lunch: false,
      cancel: false,
    }
  },
  watch: {
    absence: async function() {
      if (!this.absence) {
        this.paidAbsence = false
      } else {
        this.cancel = false
        await this.loadAbsenceReasons(null)
      }
    },
    cancel() {
      if (this.cancel) {
        this.absence = false
      }
    },
    arrivalAt: function() {
      this.onTimeChanged()
    },
    arrivalAt2: function() {
      this.onTimeChanged()
    },
    leavingAt: function() {
      this.onTimeChanged()
    },
    leavingAt2: function() {
      this.onTimeChanged()
    },
    splitTracks: function() {
      this.arrivalAt2 = null
      this.leavingAt2 = null
      this.onTimeChanged()
    },
  },
  computed: {
    modalId() {
      if (this.id) {
        return this.id
      } else {
        return 'bv-nursery-set-inscription-clocking-modal'
      }
    },
    canAddReason() {
      return this.hasPerm('nursery.add_absencereason')
    },
    canSplit: function() {
      return this.hasPerm('nursery.add_nurseryinscription') && ((+this.tracks) === 1)
    },
    hasInvoice() {
      return (
        this.inscription &&
        this.inscription.sale &&
        this.inscription.sale.invoice &&
        this.inscription.sale.invoice.id
      )
    },
    readOnly() {
      const canWrite = this.hasPerm('nursery.change_nurseryinscription')
      return !!(!canWrite || this.hasInvoice)
    },
    isValid() {
      if (this.splitTracks) {
        return this.arrivalAt && this.leavingAt && this.arrivalAt2 && this.leavingAt2
      }
      if (this.isFuture) {
        return this.arrivalAt && this.leavingAt
      }
      return true
    },
    okDisabled() {
      if (this.readOnly) {
        return true
      }
      return !this.isValid
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },
    isToday() {
      if (this.inscription) {
        return diffDate(this.today, this.inscription.date) === 0
      }
      return false
    },
    isFuture() {
      if (this.inscription) {
        return diffDate(this.today, this.inscription.date) < 0
      }
      return false
    },
    hasArrivalClocking() {
      return !!(this.inscription && this.inscription.arrivedAt)
    },
    hasLeavingClocking() {
      return !!(this.inscription && this.inscription.leftAt)
    },
    arrivedDescription() {
      if (this.inscription) {
        return 'Arrivée prévue: ' + displayHourMin(this.inscription.arrivalAt)
      }
      return ''
    },
    leftDescription() {
      if (this.inscription) {
        return 'Départ prévu: ' + displayHourMin(this.inscription.leavingAt)
      }
      return ''
    },
    showFuture() {
      if (this.splitTracks) {
        return true
      }
      if (this.cancel) {
        return false
      }
      return !(this.absence || !this.isFuture)
    },
    isAbsence() {
      return (this.absence || this.cancel)
    },
    showArrival() {
      if (this.absence || this.isFuture) {
        return false
      }
      if (this.instantClocking) {
        return !this.hasArrivalClocking
      }
      return true
    },
    showLeftAt() {
      if (this.absence || this.isFuture) {
        return false
      }
      if (this.instantClocking) {
        return this.hasArrivalClocking
      }
      return true
    },
  },
  methods: {
    displayHourMin,
    ...mapActions(['addSuccess']),
    async onConfirm(event) {
      event.preventDefault()
      this.errorText = ''
      if (this.inscription && !this.readOnly) {
        if (this.splitTracks) {
          await this.onConfirmSplit()
        } else {
          await this.onConfirmChange()
        }
      }
    },
    onTimeChanged() {
      if (this.leavingAt) {
        this.lunch = hasLunch(
          this.arrivalAt, this.leavingAt, this.splitTracks, this.arrivalAt2, this.leavingAt2
        )
      }
    },
    async onConfirmSplit() {
      try {
        const url = '/nursery/api/set-inscription-clocking/' + this.inscription.id + '/'
        const backendApi = new BackendApi('post', url)
        const data = {
          'arrival_at': this.arrivalAt,
          'leaving_at': this.leavingAt,
          'lunch': this.lunch,
        }
        const resp = await backendApi.callApi(data)
        const inscription = makeNurseryInscription(resp.data)

        const url2 = '/nursery/api/create-one-inscription/'
        const backendApi2 = new BackendApi('post', url2)
        const data2 = {
          'individual': inscription.individual.id,
          'date': dateToString(inscription.date, 'YYYY-MM-DD'),
          'arrival_at': this.arrivalAt2,
          'leaving_at': this.leavingAt2,
          'lunch': false,
          'nursery': inscription.nursery.id,
          'parent': inscription.id,
          'adaptation': inscription.adaptation,
        }
        const resp2 = await backendApi2.callApi(data2)
        const inscription2 = makeNurseryInscription(resp.data)

        this.$emit('split', { inscriptions: [inscription, inscription2], })
        let text = 'L\'inscription a été séparée en 2 plages'
        await this.addSuccess(text)
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async onConfirmChange() {
      if (this.absence && this.showAddReason && this.reasonText && this.canAddReason) {
        const url = '/nursery/api/absence-reasons/'
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi({ name: this.reasonText, })
          this.absenceReason = makeAbsenceReason(resp.data)
        } catch (err) {
          this.errorText = this.getErrorText(err)
          return
        }
      }
      try {
        const url = '/nursery/api/set-inscription-clocking/' + this.inscription.id + '/'
        const backendApi = new BackendApi('post', url)
        let absence = NurseryAbsence.None
        if (this.absence) {
          absence = this.paidAbsence ? NurseryAbsence.Paid : NurseryAbsence.Refund
        }
        let cancel = false
        if (this.allowCancel) {
          cancel = this.cancel
        }
        const data = {
          'absence': absence,
          'absence_reason': this.absenceReason ? this.absenceReason.id : null,
          'cancel': cancel,
        }
        if (!absence && !this.cancel) {
          if (this.isFuture) {
            data['arrival_at'] = this.arrivalAt
            data['leaving_at'] = this.leavingAt
            data['lunch'] = this.lunch
          } else {
            data['arrived_at'] = this.arrivedAt || null
            data['arrived_with'] = this.arrivedWith ? this.arrivedWith.id : null
            data['left_at'] = this.leftAt || null
            data['left_with'] = this.leftWith ? this.leftWith.id : null
            if (this.leftAt) {
              data['lunch'] = this.lunch
            }
          }
        }
        const resp = await backendApi.callApi(data)
        const inscription = makeNurseryInscription(resp.data)
        this.$emit('confirmed', { inscription: inscription, })
        let text = 'Le pointage a été prise en compte'
        if (this.isFuture) {
          text = 'La modification a été prise en compte'
        }
        await this.addSuccess(text)
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    onCancel() {
    },
    async loadEntity() {
      this.errorText = ''
      if (this.inscription) {
        try {
          const url = '/api/families/families/' + this.inscription.entity.id + '/'
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          const entity = makeEntity(resp.data)
          this.members = entity.memberships.filter(
            elt => !elt.role.isChild && (elt.role.isMain || elt.childrenAllowed)
          ).sort(
            (elt1, elt2) => {
              if (elt1.role.isMain && !elt2.role.isMain) {
                return -1
              } else if (!elt1.role.isMain && elt2.role.isMain) {
                return 1
              } else {
                let val = compareNumbers(elt1.role.order, elt2.role.order)
                if (val === 0) {
                  val = compareNumbers(elt1.id, elt2.id)
                }
                return val
              }
            }
          )
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      } else {
        this.entity = null
      }
    },
    async loadAbsenceReasons(absenceReason) {
      this.errorText = ''
      try {
        const url = '/nursery/api/absence-reasons/'
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        const absenceReasons = [makeAbsenceReason()].concat(resp.data.map(makeAbsenceReason))
        if (absenceReason) {
          const index = getObjIndex(absenceReason, absenceReasons)
          if (index >= 0) {
            this.showAddReason = false
            absenceReasons[index] = absenceReason
            this.reasonText = ''
          } else {
            this.showAddReason = true
            this.reasonText = absenceReason.name
          }
        }
        this.absenceReasons = absenceReasons
        this.absenceReason = absenceReason
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async init() {
      this.splitTracks = false
      this.instantClocking = (
        !this.hasInvoice && this.isToday && (!this.hasArrivalClocking || !this.hasLeavingClocking)
      )
      this.absenceReason = this.inscription.absenceReason
      this.arrivalAt = displayHourMin(this.inscription.arrivalAt)
      this.leavingAt = displayHourMin(this.inscription.leavingAt)
      this.arrivedAt = displayHourMin(this.inscription.arrivedAt)
      this.leftAt = displayHourMin(this.inscription.leftAt)
      this.absence = this.inscription.absence !== NurseryAbsence.None
      this.cancel = false
      this.reasonText = ''
      this.showAddReason = false
      if (this.inscription.absence && !this.inscription.absence.isProposed) {
        this.reasonText = this.inscription.absence.name
        this.showAddReason = true
      }
      this.paidAbsence = this.inscription.absence === NurseryAbsence.Paid
      await this.loadEntity()
      this.arrivedWith = this.inscription.arrivedWith
      this.leftWith = this.inscription.leftWith
      if (this.instantClocking && !this.absence) {
        const now = moment().format('HH:mm')
        if (!this.hasArrivalClocking) {
          this.arrivedAt = now
          if (this.members.length === 1 && !this.arrivedWith) {
            this.arrivedWith = this.members[0].individual
          }
        } else if (!this.hasLeavingClocking) {
          this.leftAt = now
          if (this.members.length === 1 && !this.leftWith) {
            this.leftWith = this.members[0].individual
          }
        }
      }
      if (this.absence) {
        await this.loadAbsenceReasons(this.inscription.absenceReason)
      }
    },
    onSplitTracks() {
      this.splitTracks = !this.splitTracks
      if (this.splitTracks) {
        this.instantClocking = false
      } else {
        this.instantClocking = (
          !this.hasInvoice && this.isToday && (!this.hasArrivalClocking || !this.hasLeavingClocking)
        )
      }
    },
    setDefaultArrivedAt() {
      this.arrivedAt = displayHourMin(this.inscription.arrivalAt)
    },
    setDefaultLeftAt() {
      this.leftAt = displayHourMin(this.inscription.leavingAt)
    },
  },
}
</script>
<style lang="less" scoped>
.nursery-clocking .fa-clock {
  margin-top: 45px;
  display: inline-block;
}
.help-info {
  color: #888;
  font-size: 12px;
}
.set-default-btn {
  color: #0b2e79;
  font-size: 12px;
  text-align: right;
}
</style>
